import { BaseService } from './../base'
export class RequestService extends BaseService {
    static get entity () {
        return 'request'
    }
    static get name () {
        return 'request_form'
    }
    static async create(data) {
        return this.submit('post', `${process.env.VUE_APP_API_URL}/api/${this.entity}`, "request_form_update",data);
    }
    // static async getByRangeDate(dateStart, dateEnd) {
    //     return this.submit('get', `${this.profile}/${this.entity}/find_by_range/${dateStart}/${dateEnd}`, this.name);
    // }
    static async sendEmail(data) {
        return this.submit('post', `${process.env.VUE_APP_API_URL}/api/${this.entity}/sendEmail`,this.name, data);
    }
    static async getByRangeDate(dateStart, dateEnd, page = 1, itemsPerPage = 15) {
        return this.submit('get', `${this.profile}/${this.entity}/find_by_rangePaginatedNoWhere/${dateStart}/${dateEnd}/${itemsPerPage}?page=${page}`, this.name);
    }
    static async getByRangeDateFilter(dateStart, dateEnd, dataToSearch, page = 1, itemsPerPage = 15) {
        return this.submit('get', `${this.profile}/${this.entity}/find_by_rangePaginated/${dateStart}/${dateEnd}/${dataToSearch}/${itemsPerPage}?page=${page}`, this.name);
    }
    static async getPaginated(page = 1, itemsPerPage = 15) {
        return this.submit('get', `${this.profile}/${this.entity}/indexPaginatedNoWhere/${itemsPerPage}?page=${page}`, this.name);
    }
    static async getByFilter(dataToSearch, page = 1, itemsPerPage = 15) {
        return this.submit('get', `${this.profile}/${this.entity}/indexPaginated/${dataToSearch}/${itemsPerPage}?page=${page}`, this.name);
    }
    static async getByRequestCode(code){
        return this.submit("get", `${this.entity}/find_by_code/${code}`, 'request_form');
    }
}