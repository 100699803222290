<template>
  <PlantillaPublic>
    <div slot="contenido">
      <v-card :loading="loading" :disabled="loading">
        <v-card-title> Solicitud </v-card-title>
        <v-card-subtitle> Datos personal </v-card-subtitle>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                @change="changeRequestType"
                :items="requestType"
                item-text="name"
                item-value="id"
                v-model="form.request_type_id"
                label="Seleccionar Tipo de Solicitud"
                solo
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                @change="changeRequestTypeDetail"
                :items="requestTypeDetail"
                item-text="name"
                item-value="id"
                v-model="form.request_type_detail_id"
                :disabled="!form.request_type_id"
                label="Seleccionar Tipo"
                solo
              ></v-select>
            </v-col>
          </v-row>
          <v-form v-model="valid" ref="myForm">
            <bonus-of-benefits-form
              ref="bonusBenefitForm"
              v-if="form.request_type_id && form.request_type_detail_id"
              :typeForm="form.request_type_id"
            />
          </v-form>
          <br />
        </v-card-text>

        <v-card-actions class="mb-4">
          <v-btn
            color="info"
            dark
            right
            absolute
            :disabled="!form.request_type_id"
            @click.native="postForm"
          >
            Acepto
          </v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </PlantillaPublic>
</template>
<script>
import PlantillaPublic from "../templates/PlantillaPublic.vue";
import BonusOfBenefitsForm from "../components/Forms/BonusOfBenefitsForm.vue";
import { RequestTypeService } from "../services/models/requestType";
import { RequestTypeDetailService } from "../services/models/requestTypeDetail";
import { RequestService } from "../services/models/request";
import { RequestFileService } from "../services/models/requestFile";
import { RequestFilesSalariesService } from "../services/models/requestFilesSalaries";

import { mapMutations } from "vuex";

export default {
  components: {
    PlantillaPublic,
    BonusOfBenefitsForm,
  },
  data() {
    return {
      valid: false,
      requestType: [],
      requestTypeDetail: [],
      loading: false,
      form: {
        request_type_id: 0,
        request_type_detail_id: null,
      },
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapMutations({
      loadRequestTypeDetailSelected:
        "requestTypeDetail/loadRequestTypeDetailSelected",
    }),
    async sendMail(email,message) {      
      let data = {
        email: email,
        message: message,
      };
      await RequestService.sendEmail(data);
    },
    async loadData() {
      this.loading = true;
      let result = await RequestTypeService.getAll();
      this.requestType.push(...result);
      this.loading = false;
    },
    async changeRequestType() {
      if (this.form.request_type_id !== 0) {
        this.loading = true;
        this.requestTypeDetail =
          await RequestTypeDetailService.getByRequestType(
            this.form.request_type_id
          );
        this.form.request_type_detail_id = this.requestTypeDetail[0].id;
        this.changeRequestTypeDetail();
        this.loading = false;
      } else {
        this.requestTypeDetail = [];
      }
    },
    changeRequestTypeDetail() {
      let requestTypeDetailSelected = this.requestTypeDetail.find(
        (value) => value.id == this.form.request_type_detail_id
      );
      this.loadRequestTypeDetailSelected(requestTypeDetailSelected);
    },
    async postForm() {
      if (this.valid) {
        this.loading = true;
        try {
          let dataApplicant = this.$refs.bonusBenefitForm.applicant;
          let dataFiles = this.$refs.bonusBenefitForm.$refs.uploadFile.files;
          console.log(this.form.request_type_id);
          if (this.form.request_type_id==3) {
            let dataSalariesFiles = this.$refs.bonusBenefitForm.$refs.uploadFileSalaries?.files_salaries;
            if (dataSalariesFiles.length>0) {
               // let dataPost = {
          //   request_type_id: this.form.request_type_id,
          //   request_type_detail_id: this.form.request_type_detail_id,
          //   ...dataApplicant,
          //   state: "pendiente",
          //   rut: dataApplicant.rut.replace(/^0+|[^0-9kK-]+/g, "").toUpperCase(),
          // };
          let formDataRequest = new FormData();
          formDataRequest.append("state", "pendiente");
          formDataRequest.append("request_type_id", this.form.request_type_id);
          formDataRequest.append("request_type_detail_id", this.form.request_type_detail_id);
          formDataRequest.append("rut", dataApplicant.rut.replace(/^0+|[^0-9kK-]+/g, "").toUpperCase());
          formDataRequest.append("name_applicant", dataApplicant.name_applicant);
          formDataRequest.append("first_lastname_applicant", dataApplicant.first_lastname_applicant);
          formDataRequest.append("second_lastname_applicant", dataApplicant.second_lastname_applicant);
          formDataRequest.append("email", dataApplicant.email);
          formDataRequest.append("cellphone_applicant", dataApplicant.cellphone_applicant);
          formDataRequest.append("stablishment_id", dataApplicant.stablishment_id);
          formDataRequest.append("services_applicant", dataApplicant.services_applicant);
          formDataRequest.append("unities_applicant", dataApplicant.unities_applicant);
          formDataRequest.append("position", dataApplicant.name_applicant);
          formDataRequest.append("grade", dataApplicant.grade);
          formDataRequest.append("document_identification", dataApplicant.document_identification);
          formDataRequest.append("is_retired", dataApplicant.is_retired ? 1 : 0);
          let responseRequest = await RequestService.create(formDataRequest, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          for (let i = 0; i < dataFiles.length; i++) {
            let formData = new FormData();
            formData.append("request_id", responseRequest.id);
            formData.append("file", dataFiles[i].file);
            await RequestFileService.create(formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
          for (let i = 0; i < dataSalariesFiles.length; i++) {
            let formData = new FormData();
            formData.append("request_id", responseRequest.id);
            formData.append("file", dataSalariesFiles[i].file);
            await RequestFilesSalariesService.create(formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
          let refData = this.$refs.bonusBenefitForm;          
          let message = `
            <div>
                <h2>Estimado/a</h2><br>          
                  Su Solicitud fue recibida con éxito con los siguientes datos
                <br>
                <ul>
                  <li>Nombre del Solicitante: ${refData.applicant.name_applicant} ${refData.applicant.first_lastname_applicant} ${refData.applicant.second_lastname_applicant}</li>
                  <li>Rut: ${refData.applicant.rut}</li>
                  <li>Servicio: ${refData.applicant.services_applicant}</li>
                  <li>Unidad o CR: ${refData.applicant.unities_applicant}</li>
                  <li>Telefono: ${refData.applicant.cellphone_applicant}</li>
                  <li>Correo: ${refData.applicant.email}</li>            
                </ul>       
              </div>
              `;
              let messageToSSM=`
                <div>
                <h2>Estimados</h2><br>          
                  Llego una solicitud desde Beneficios.dssm.cl con los siguientes datos:
                <br>
                <ul>
                  <li>Nombre del Solicitante: ${refData.applicant.name_applicant} ${refData.applicant.first_lastname_applicant} ${refData.applicant.second_lastname_applicant}</li>
                  <li>Rut: ${refData.applicant.rut}</li>
                  <li>Servicio: ${refData.applicant.services_applicant}</li>
                  <li>Unidad o CR: ${refData.applicant.unities_applicant}</li>
                  <li>Telefono: ${refData.applicant.cellphone_applicant}</li>
                  <li>Correo: ${refData.applicant.email}</li>            
                </ul>       
              </div>
              `;
          this.sendMail(refData.applicant.email,message);
          this.sendMail('bienestar.ssm@redsalud.gov.cl',messageToSSM);
          this.$router.push({ name: "success",  params: { responseRequest } });
            }else{
              this.displayToast({
              type: "error",
              message: "Error al guardar, Debe adjuntar liquidación de sueldo",
            });
            }
          }
          if (this.form.request_type_id==1) {
             // let dataPost = {
          //   request_type_id: this.form.request_type_id,
          //   request_type_detail_id: this.form.request_type_detail_id,
          //   ...dataApplicant,
          //   state: "pendiente",
          //   rut: dataApplicant.rut.replace(/^0+|[^0-9kK-]+/g, "").toUpperCase(),
          // };
          let formDataRequest = new FormData();
          formDataRequest.append("state", "pendiente");
          formDataRequest.append("request_type_id", this.form.request_type_id);
          formDataRequest.append("request_type_detail_id", this.form.request_type_detail_id);
          formDataRequest.append("rut", dataApplicant.rut.replace(/^0+|[^0-9kK-]+/g, "").toUpperCase());
          formDataRequest.append("name_applicant", dataApplicant.name_applicant);
          formDataRequest.append("first_lastname_applicant", dataApplicant.first_lastname_applicant);
          formDataRequest.append("second_lastname_applicant", dataApplicant.second_lastname_applicant);
          formDataRequest.append("email", dataApplicant.email);
          formDataRequest.append("cellphone_applicant", dataApplicant.cellphone_applicant);
          formDataRequest.append("stablishment_id", dataApplicant.stablishment_id);
          formDataRequest.append("services_applicant", dataApplicant.services_applicant);
          formDataRequest.append("unities_applicant", dataApplicant.unities_applicant);
          formDataRequest.append("position", dataApplicant.name_applicant);
          formDataRequest.append("grade", dataApplicant.grade);
          formDataRequest.append("document_identification", dataApplicant.document_identification);
          formDataRequest.append("is_retired", dataApplicant.is_retired ? 1 : 0);
          let responseRequest = await RequestService.create(formDataRequest, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
          for (let i = 0; i < dataFiles.length; i++) {
            let formData = new FormData();
            formData.append("request_id", responseRequest.id);
            formData.append("file", dataFiles[i].file);
            await RequestFileService.create(formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            });
          }
        
          let refData = this.$refs.bonusBenefitForm;          
          let message = `
            <div>
                <h2>Estimado/a</h2><br>          
                  Su Solicitud fue recibida con éxito con los siguientes datos
                <br>
                <ul>
                  <li>Nombre del Solicitante: ${refData.applicant.name_applicant} ${refData.applicant.first_lastname_applicant} ${refData.applicant.second_lastname_applicant}</li>
                  <li>Rut: ${refData.applicant.rut}</li>
                  <li>Servicio: ${refData.applicant.services_applicant}</li>
                  <li>Unidad o CR: ${refData.applicant.unities_applicant}</li>
                  <li>Telefono: ${refData.applicant.cellphone_applicant}</li>
                  <li>Correo: ${refData.applicant.email}</li>            
                </ul>       
              </div>
              `;
              let messageToSSM=`
                <div>
                <h2>Estimados</h2><br>          
                  Llego una solicitud desde Beneficios.dssm.cl con los siguientes datos:
                <br>
                <ul>
                  <li>Nombre del Solicitante: ${refData.applicant.name_applicant} ${refData.applicant.first_lastname_applicant} ${refData.applicant.second_lastname_applicant}</li>
                  <li>Rut: ${refData.applicant.rut}</li>
                  <li>Servicio: ${refData.applicant.services_applicant}</li>
                  <li>Unidad o CR: ${refData.applicant.unities_applicant}</li>
                  <li>Telefono: ${refData.applicant.cellphone_applicant}</li>
                  <li>Correo: ${refData.applicant.email}</li>            
                </ul>       
              </div>
              `;
          this.sendMail(refData.applicant.email,message);
          this.sendMail('bienestar.ssm@redsalud.gov.cl',messageToSSM);
          this.$router.push({ name: "success",  params: { responseRequest } });
          }
        } catch (error) {
          console.log(error);
          this.displayToast({
            type: "error",
            message: "Error al guardar, favor intentar de nuevo",
          });
        } finally {
          this.loading = false;
        }
      } else {
        this.$refs.myForm.validate();
        this.displayToast({
          type: "warning",
          message: `Favor de llenar todos los campos del formulario`,
        });
      }
    },
  },
};
</script>