<template>
  <section>
    <v-card color="info" dark>
      <v-card-text v-if="getRequestTypeDetailSelected.id == 11">
        Para solicitarlo, requiere adjuntar formulario completo.
      </v-card-text>
      <v-card-text
        v-if="
          getRequestTypeDetailSelected.id == 9 ||
          getRequestTypeDetailSelected.id == 10 ||
          getRequestTypeDetailSelected.id == 12
        "
      >
        Para solicitarlo, requiere adjuntar formulario completo y antecedentes
        médicos que respalden su solicitud
      </v-card-text>
    </v-card>
    <br />
    <v-card>
      <v-card-text>
        <!-- <v-row>
          <v-card color="error">
            <v-card-text style="color: white">
              Los campos con el simbolo * son obligatorios
            </v-card-text>
          </v-card>
        </v-row> -->
        <v-row>
          <v-col cols="12" lg="2" sm="2">
          <v-checkbox label="Jubilado" v-model="applicant.is_retired" @click="retiredUser"></v-checkbox>

          </v-col>
          <v-col cols="12" lg="4" sm="10">
            <v-text-field
              v-model="applicant.name_applicant"
              required
              :rules="fieldRultes"
              label="Nombre"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" sm="6" xs="12">
            <v-text-field
              v-model="applicant.first_lastname_applicant"
              label="Apellido Paterno"
              required
              :rules="fieldRultes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="3" sm="6" xs="12">
            <v-text-field
              v-model="applicant.second_lastname_applicant"
              label="Apellido Materno"
              required
              :rules="fieldRultes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.rut"
              label="RUN"
              required
              @keypress.native="isValidCharacter($event)"
              @input="formatRutApplicant"
              :rules="fieldRultes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.email"
              label="E-mail"
              required
              :rules="fieldRultes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.cellphone_applicant"
              label="Celular"
              required
              :rules="fieldRultes"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-select
              :items="stablishments"
              item-text="name"
              item-value="id"
              required
              v-model="applicant.stablishment_id"
              :rules="fieldRultes"
              label="Seleccionar Establecimiento"
              solo
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.services_applicant"
              required
              :rules="fieldRultes"
              label="Servicio"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.unities_applicant"
              required
              :rules="fieldRultes"
              label="Unidad/CR"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.position"
              required
              :rules="fieldRultes"
              label="Cargo"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" lg="3">
            <v-text-field
              v-model="applicant.grade"
              required
              :rules="fieldRultes"
              label="Grado"
            ></v-text-field>
          </v-col>
        </v-row>
        <div>
          <v-row>
            <v-col cols="12" sm="6">
              <v-card color="#26c6da" dark>
                <v-card-text style="color: white">
                  Cada solicitud debe ser acompañada con adjunto de cédula de
                  identidad correspondiente al socio(a) de Bienestar. Es
                  importante señalar que el contenido de los documentos deben
                  observarse claramente para el reembolso
                </v-card-text>
              </v-card>
              <br />
              <!-- <v-row>
                <v-col cols="12" sm="8"> -->
                  <v-file-input
                    label="Adjuntar cédula de identidad"
                    prepend-icon="mdi-folder-upload"
                    chips
                    counter
                    show-size
                    :clearable="false"
                    v-model="applicant.document_identification"
                    required
                    :rules="fieldRultes"
                  >
                    <template v-slot:selection="{ text }">
                      <v-chip small label color="info">
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
                  
                <!-- </v-col>
              </v-row> -->
              <!-- <v-btn
                v-if="getRequestTypeDetailSelected.id == 8"
                color="blue-grey"
                class="ma-2 white--text"
                @click="openFile('/FORMULARIO BONO ESCOLAR 2021.pdf')"
              >
                Formulario bono escolar
                <v-icon right dark> mdi-file </v-icon>
              </v-btn> -->
              <v-btn
                v-if="
                  getRequestTypeDetailSelected.id == 9 ||
                  getRequestTypeDetailSelected.id == 10 ||
                  getRequestTypeDetailSelected.id == 11
                "
                color="blue-grey"
                class="ma-2 white--text"
                @click="openFile('/Formulario de prestamo 2021.pdf')"
              >
                Formulario de préstamo
                <v-icon right dark> mdi-file </v-icon>
              </v-btn>
              <v-btn
                v-if="getRequestTypeDetailSelected.id == 12"
                color="blue-grey"
                class="ma-2 white--text"
                @click="openFile('/FORMULARIO HABITACIONAL 2021.pdf')"
              >
                Formulario habitacional
                <v-icon right dark> mdi-file </v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6">
              <upload-file ref="uploadFile" />
            </v-col>
            <v-col cols="12" sm="6">
                    <upload-files-salaries v-if="this.typeForm==3" ref="uploadFileSalaries" />
                  </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <br />
    <div v-if="typeForm == 1">
      <v-card>
        <v-card-text>
          Contemplado en el Reglamento de Bienestar Decreto Supremo Nº 29 de
          1997, y para lo cual declaro ser imponente y estar al día en el pago
          de mis imposiciones. Tomo conocimiento que la Unidad de Bienestar hará
          efectivo el pago correspondiente, de acuerdo a la modalidad de recibo
          de remuneraciones que tengo con el Servicio de Salud (depósito
          bancario – Pago Cash). Como afiliado al S. de Bienestar declaro dar fe
          que los antecedentes presentados adjuntos a esta solicitud son
          fidedignos y tomo conocimiento que: el S. de Bienestar puede verificar
          la validez de éstos y que se encuentra contemplado en el D.S Nº 28:
          “al obtener beneficios económicos valiéndose de documentos o datos
          falsos, éstos deberán ser reembolsados reajustados en un 100% del
          interés respectivo dentro de los límites establecidos por la Ley Nº
          18.010”.b
          <p class="text-right">
            <b
              >Al aceptar, se dará por entendido que se aceptan los términos y
              condiciones.</b
            >
          </p>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="typeForm == 2">
      <v-card>
        <v-card-text>
          Solicito al Servicio de Bienestar se me otorgue el beneficio de:
          SUBSIDIO ESCOLAR: Contemplado en el Reglamento de Bienestar Decreto 29
          de 1997, y para lo cual declaro ser afiliado y estar al día en el pago
          de mis imposiciones.
          <p class="text-right">
            <b
              >Al aceptar, se dará por entendido que se aceptan los términos y
              condiciones.</b
            >
          </p>
        </v-card-text>
      </v-card>
    </div>
  </section>
</template>
<script>
import { mapGetters } from "vuex";
import UploadFile from "../../components/Forms/UploadFile.vue";
import { StablishmentService } from "../../services/models/stablishment";
import { formatRut } from "./../../thirdParty/utils";
import UploadFilesSalaries from './UploadFilesSalaries.vue';

export default {
  props: {
    typeForm: {
      type: Number,
    },
  },
  components: {
    UploadFile,
    UploadFilesSalaries,
  },
  data() {
    return {
      stablishments: [],
      applicant: {
        document_identification: null,
        is_retired:0
      },
      fieldRultes: [(v) => !!v || "Este campo es obligatorio"],
    };
  },
  mounted() {
    this.loadData();
  },
  computed: {
    ...mapGetters({
      getRequestTypeDetailSelected:
        "requestTypeDetail/getRequestTypeDetailSelected",
    }),
  },
  methods: {
    retiredUser(){
      if (this.applicant.is_retired) {
        this.applicant.services_applicant = 'Jubilado'
        this.applicant.unities_applicant = 'Jubilado'
        this.applicant.position = 'Jubilado'
        this.applicant.grade = 'Jubilado'
        this.applicant.stablishment_id = 6
      }else{
        this.applicant.services_applicant = ''
        this.applicant.unities_applicant = ''
        this.applicant.position = ''
        this.applicant.grade = ''
        this.applicant.stablishment_id = null
      }
    },
    openFile(fileUrl) {
      window.open(fileUrl, "_blank");
    },
    isValidCharacter(evt) {
      const keysAllowed = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "K",
        "k",
      ];
      const keyPressed = evt.key;
      if (!keysAllowed.includes(keyPressed)) {
        evt.preventDefault();
      }
    },
    formatRutApplicant() {
      this.applicant.rut = formatRut(this.applicant.rut);
    },
    async loadData() {
      this.stablishments = await StablishmentService.getAll();
    },
  },
};
</script>