<template>
  <section>
    <v-row>
      <v-col cols="12" sm="8">
        <v-file-input
          label="Adjuntar Archivos"
          prepend-icon="mdi-folder-upload"
          chips
          counter
          show-size
          :clearable="false"
          v-model="file"
        >
          <template v-slot:selection="{ text }">
            <v-chip small label color="info">
              {{ text }}
            </v-chip>
          </template>
        </v-file-input>
      </v-col>
      <v-col cols="12" sm="4">
        <br />
        <v-btn
          color="primary"
          :disabled="file.length == 0"
          label="Adjuntar"
          @click="addFile"
        >
          Adjuntar
        </v-btn>
      </v-col>
    </v-row>

    <!-- -->
    <v-simple-table v-if="files.length">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Nombre</th>
            <th class="text-left">Eliminar</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in files" :key="index">
            <td>{{ item.name }}</td>
            <td>
              <v-btn
                icon
                color="error"
                outlined
                @click="files.splice(index, 1)"
              >
                <v-icon dark> mdi-delete </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-alert v-else border="bottom" colored-border type="warning" elevation="2">
      No hay archivos ingresados
    </v-alert>
    <!--  -->
  </section>
</template>
<script>
export default {
  data() {
    return {
      files: [],
      file: [],
    };
  },
  methods: {
    addFile() {
      if(this.file.length!==0){
        this.files.push({
          name: this.file.name,
          file: this.file,
        });
      }
    },
  },
};
</script>